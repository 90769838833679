<template>
  <main class="main page-inside products-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="title big">
            <router-link to="/categories" class="back"></router-link>
            {{activeCategory.title}}</div>
        <div class="wrapper">
          <transition name="fade">
            <div class="list products" v-if="products">
              <div class="item" v-for="(item, i) in products" :key="i">
                <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
              </div>
            </div>
          </transition>
        </div>
        <div class="title small server-status absolute">SERVER STATUS: <span class="green">ONLINE</span></div>
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';
export default {
  name: 'Products',
  props: ['addToCartChosenItem', 'currency', 'activeCategory', 'currencyCode'],
  components: {
    ProductCard
  },
  data: function() {
    return {
      products: [],
      category: '' 
    }
  },
  watch: {
    activeCategory: function() {
      this.getProducts();
    },
    currencyCode: function() {
      this.getProducts();
    },
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    
    getProducts() {
      this.$http.get(process.env.VUE_APP_API + 'products/?category_id=' + this.activeCategory.id)
      .then((res) => {
        this.products = res.data.payload;
      })
      .catch(() => {
         
      })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
  }
}
</script>