import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: true,
    timeout: 5000, // set the default timeout for requests to 5 seconds
});

instance.interceptors.request.use(function (config) {
    let currency = localStorage.getItem('currency');
    if (!currency) {
        currency = 'USD'
    }
    if (config.method === 'get') {
        config.params = {
            ...config.params,
            currency
        }

    } else if(['post', 'put', 'patch', 'POST', 'PUT', 'PATCH'].includes(config.method)) {
        config.data = {
            ...config.data,
            currency
        }
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default instance;