<template>
  <div class="modal reg-modal page-inside order-page">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="container">
            <div class="form">
              <div class="form-wrapper">
                <div class='form-fields register-content'>
                  <div class="title regular">
                    CHECKOUT
                    <router-link to="/cart" class="button">
                      BACK
                    </router-link>
                  </div>
                  <div class="form-fields-wrapper">
                    <label>
                      <div class="desc">First name:</div>
                      <input type="text" placeholder="First name" v-model="name"/>
                    </label>
                    <label>
                      <div class="desc">Last Name:</div>
                      <input type="text" placeholder="Last Name" v-model="surname"/>
                    </label>
                    <label>
                      <div class="desc">Email:</div>
                      <input type="email" placeholder="Email" v-model="email"/>
                    </label>
                    <label>
                      <div class="desc">Nickname:</div>
                      <input type="text" placeholder="Nickname" v-model="nickname"/>
                    </label>
                    <label>
                      <div class="desc">Phone:</div>
                      <input type="text" placeholder="Phone" @input="allowOnlyNumbers" v-model="phone"/>
                    </label>
                    <label>
                      <div class="desc">Address:</div>
                      <input type="text" v-model="address"/>
                    </label>
                    <label>
                      <div class="desc">City:</div>
                      <input type="text" v-model="city"/>
                    </label>
                    <label>
                      <div class="desc">Country:</div>
                      <select v-model="country">
                        <option v-for="item in countryOptions" :value="item" :key="item.id">{{item.title}}</option>
                      </select>
                    </label>
                    <label>
                      <div class="desc">Zip Code:</div>
                      <input type="text" v-model="zipCode"/>
                    </label>
                    <div class="cta-container">
                      <div class="chekbox-container">
                        <label class="chekbox-label">
                          <div class="chekbox-label-wrapper">
                            <input type="checkbox" name="terms" v-model="terms"/>
                            <div class="checkbox"></div>
                            <span class="desc">I agree with </span>
                            <a @click="$parent.goToPrivacy()" class="desc"> privacy policy</a>
                            <span class="desc"> and </span>
                            <a @click="$parent.goToTerms()" class="desc"> terms and conditions</a>
                          </div>
                        </label>
                      </div>
                      <div class="button-container">
                        <button :class="['button red long', {'disabled': !requiredFieldsAreFilled}]" @click="submit">
                          <span>Debit / Credit card</span>
                        </button>
                      </div>
                    </div>
                    <transition name="slide">
                      <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Registration',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      name: '',
      phone: '',
      surname: '',
      nickname: '',
      country: '',
      countryId: '',
      countryOptions: '',
      city: '',
      address: '',
      zipCode: '',
      terms: false
    }
  },
  mounted() {
    this.getCounties();
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.country && this.city && this.address  
        && this.zipCode && this.terms && this.email && this.nickname
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    allowOnlyNumbers(){
      this.phone = this.phone.replace(/[^0-9.,]/g, '');
      const parts = this.phone.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }
      this.phone = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    submit() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "email": this.email,
        "nickname": this.nickname,
        "country": this.country.id,
        "city": this.city,
        "address": this.address,
        "postCode": this.zipCode
      }
      this.$emit('orderSubmit', data)
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    
  }
}
</script>