<template>
  <main class="main page-inside text-page contact-us-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title regular">
            Contact us
          </div>
          <div class="title regular contacts">
              Reach out by using the form below or by calling +447458197378
          </div>
          <div class="content form-fields-wrapper">
            <label>
              <div class="desc">Name:</div>
              <input type="text" placeholder="Name" v-model="name"/>
            </label>
            <label>
              <div class="desc">Email:</div>
              <input type="text" placeholder="Email" v-model="email"/>
            </label>
            <label>
              <div class="desc">Message</div>
              <textarea placeholder="Message" v-model="message"></textarea>
            </label>
            <button class="button red long" @click="submit">Send</button>
            <transition name="fade">
              <div v-if="$parent.successMessage" class="desc small green">{{$parent.successMessage}}</div>
            </transition>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'ContactUs',
  props: ['successMessage'],
  components: {
    
  },
  data: function() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  watch: {
    successMessage: function(newVal) {
      if (newVal.length) {
        this.name = '';
        this.email = '';
        this.message = '';
      }
    },
  },
  methods: {
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    },
  }
}
</script>