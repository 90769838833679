<template>
	<header class="header">
        <div class="wrapper">
          <div class="left">
            <ul class="nav">
              <li class="nav__item">
                <router-link to="/">
                  <img src="./../assets/homeIcon.svg" class="img"/>
                  <span class="desc">Home</span>
                </router-link>
              </li>
              <li class="nav__item">
                <a @click="$parent.goToRules">
                  <img src="./../assets/rulesIcon.svg" class="img"/>
                  <span class="desc">Rules</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="center">
            <div class="logo">
              <img src="./../assets/logo.svg" class="img"/>
            </div>
          </div>
          <div class="right">
            <div class="select-container" v-if="curr">
              <Select2 class="select2 single currency-select" v-model="curr"
                      @select="selectCurr($event)"
                      :placeholder="curr"
                      :options="currOptions"
              />
            </div>
            <router-link :to="'/store' + activeCategory.url" class="button red">
              Store
            </router-link>
          </div>
        </div>
    </header>
</template>

<script>

import Select2 from 'vue3-select2-component';
export default {
	name: 'Header',
  props: ['activeCategory', 'currencyCode'],
  components: {
    Select2
  },
	data: function() {
		return {
      curr: ''
		}
	},
  computed: {
    currOptions() {
      return this.$parent.currencyOptions.map(({text}) => text);
    }
  },
  watch: {
    currencyCode: function (newValue) {
      this.curr = newValue
    },
  },
  mounted() {
    this.curr = this.currencyCode
  },
	methods: {
    openSignIn() {
      this.$router.push({path: '/login'});
    },
    openSignUp() {
      this.$router.push({path: '/registration'});
    },
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text)
      localStorage.setItem("currency", event.text);
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
    
	}
}
</script>
