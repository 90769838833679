<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="title">
          <span>ZOMBIFYCRAFT:</span>
          <span class="red">SURVIVE THE APOCALYPSE</span>
        </div>
        <div class="ticker-wrap">
          <div class="ticker">
            <div class="item item-collection-1">
              <span class="title">WE ARE ALWAYS ONLINE</span>
              <span class="title">WE ARE ALWAYS ONLINE</span>
              <span class="title">WE ARE ALWAYS ONLINE</span>
              <span class="title">WE ARE ALWAYS ONLINE</span>
            </div>
            <div class="item">
              <span class="title">WE ARE ALWAYS ONLINE</span>
              <span class="title">WE ARE ALWAYS ONLINE</span>
              <span class="title">WE ARE ALWAYS ONLINE</span>
              <span class="title">WE ARE ALWAYS ONLINE</span>
            </div>
          </div>
        </div>
        <div class="hero-info-container">
          <div class="hero-info-wrapper">
            <div class="hero-info hero-info-1">
              <div class="hero-info__item">
                <div class="title title-block">
                  <span>Servers:</span>
                  <img src="./../assets/heroInfo/1.png" class="img"/>
                </div>
                <div class="info-list">
                  <div class="info-item">
                    <div class="desc">Total:</div>
                    <div class="title item-title">56 492</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Online:</div>
                    <div class="title item-title green">5 837</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Excluded:</div>
                    <div class="title item-title">50 837</div>
                  </div>
                </div>
              </div>
              <div class="hero-info__item">
                <div class="title title-block">
                  <span>Players:</span>
                  <img src="./../assets/heroInfo/2.png" class="img"/>
                </div>
                <div class="info-list">
                  <div class="info-item">
                    <div class="desc">Total:</div>
                    <div class="title item-title">19 970 455</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Online:</div>
                    <div class="title item-title green">5 837</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Today:</div>
                    <div class="title item-title blue">2 628</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Average:</div>
                    <div class="title item-title">50 837</div>
                  </div>
                </div>
              </div>
              <div class="hero-info__item">
                <div class="title title-block">
                  <span>Plugins:</span>
                  <img src="./../assets/heroInfo/3.png" class="img"/>
                </div>
                <div class="info-list">
                  <div class="info-item">
                    <div class="desc">Total:</div>
                    <div class="title item-title">79 840</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">#1:</div>
                    <div class="title item-title blue">Vault</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">#2:</div>
                    <div class="title item-title blue">LuckPerms</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">#2:</div>
                    <div class="title item-title blue">WorldGuard</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hero-info">
              <div class="hero-info__item">
                <div class="title title-block">
                  <span>Servers:</span>
                  <img src="./../assets/heroInfo/1.png" class="img"/>
                </div>
                <div class="info-list">
                  <div class="info-item">
                    <div class="desc">Total:</div>
                    <div class="title item-title">56 492</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Online:</div>
                    <div class="title item-title green">5 837</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Offline:</div>
                    <div class="title item-title red">432</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Excluded:</div>
                    <div class="title item-title">50 837</div>
                  </div>
                </div>
              </div>
              <div class="hero-info__item">
                <div class="title title-block">
                  <span>Players:</span>
                  <img src="./../assets/heroInfo/2.png" class="img"/>
                </div>
                <div class="info-list">
                  <div class="info-item">
                    <div class="desc">Total:</div>
                    <div class="title item-title">19 970 455</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Online:</div>
                    <div class="title item-title green">5 837</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Today:</div>
                    <div class="title item-title blue">2 628</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">Average:</div>
                    <div class="title item-title">50 837</div>
                  </div>
                </div>
              </div>
              <div class="hero-info__item">
                <div class="title title-block">
                  <span>Plugins:</span>
                  <img src="./../assets/heroInfo/3.png" class="img"/>
                </div>
                <div class="info-list">
                  <div class="info-item">
                    <div class="desc">Total:</div>
                    <div class="title item-title">79 840</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">#1:</div>
                    <div class="title item-title blue">Vault</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">#2:</div>
                    <div class="title item-title blue">LuckPerms</div>
                  </div>
                  <div class="info-item">
                    <div class="desc">#2:</div>
                    <div class="title item-title blue">WorldGuard</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-about">
        <div class="wrapper">
          <div class="text">
            <div class="desc red">About</div>
            <div class="title">ZOMBIFYCRAFT</div>
            <div class="desc">
              Dive into a post-apocalyptic Minecraft world where survival is the only goal. As night falls, the undead rise. Team up, fortify, and fight to reclaim the world block by block.
            </div>
          </div>
          <div class="img-container">
            <img src="./../assets/about.png" class="img"/>
          </div>
        </div>
      </div>
      <div class="section section-features">
        <div class="wrapper">
          <div class="title">KEY FEATURES:</div>
          <div class="features-list">
            <div class="features-list__item">
              <img src="./../assets/features/1.svg" class="img"/>
              <div class="desc big">Dynamic World Generation:</div>
              <div class="desc small">
                Dynamic World Generation: Explore vast wastelands, abandoned cities, and haunted graveyards. Every terrain is crafted to challenge and thrill.
              </div>
            </div>
            <div class="features-list__item">
              <img src="./../assets/features/2.svg" class="img"/>
              <div class="desc big">Unique Zombie Types:</div>
              <div class="desc small">
                Face off against a variety of undead foes, from the common walker to the fearsome brute. Each zombie type presents its own set of challenges.
              </div>
            </div>
            <div class="features-list__item">
              <img src="./../assets/features/3.svg" class="img"/>
              <div class="desc big">Survivor Outposts:</div>
              <div class="desc small">
                Discover and join survivor outposts scattered throughout the world. Trade, plan, and collaborate to fend off the undead hordes.
              </div>
            </div>
            <div class="features-list__item">
              <img src="./../assets/features/4.svg" class="img"/>
              <div class="desc big">Customizable Bases:</div>
              <div class="desc small">
                Build and fortify your own safe haven. Use unique blocks and traps to keep the zombies at bay.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-join">
        <img src="./../assets/bg3.jpg" class="img"/>
        <div class="wrapper">
          <img src="./../assets/sword2.png" class="sword1"/>
          <img src="./../assets/sword1.png" class="sword2"/>
          <div class="title">JOIN US TODAY!</div>
          <div class="desc big">
            Are you ready to face the apocalypse? Grab your gear, gather your friends, and dive into ZombifyCraft. The undead await...
          </div>
          <div class="desc">
            To access our game server, ensure you have a legitimate copy of Minecraft (version 1.20.4) installed on your computer. Once set up, enter our server IP: {{ ip }} into the server launcher and hit 'Join!'
          </div>
          <button @click="copyIp" class="button red">{{ipCopied ? 'IP copied' : 'CONNECT NOW'}}</button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>


export default {
  name: 'Home',
  props: [],
  components: {
  },
  data: function() {
    return {
      ip: '185.230.64.25:25565',
      email: '',
      name: '',
      message: '',
      nickname: '',
      activePanels: [],
      faq: [
        {
          title: 'SERVER ACCESS',
          content: 'Our server is free to play, and we also have an in-game items store to enhance your gaming experience.'
        },
        {
          title: 'LOGGING INTO THE SERVER',
          content: 'To log in to our game server, you need to install a licensed copy of Minecraft on your computer. Once installed, input our server IP address, which is 5.187.1.159:25565, into the server launcher, and click "Join" to start playing.'
        },
        {
          title: 'BUYING PRODUCTS',
          content: 'To purchase products, simply enter your nickname in the form provided and click on the "products" button.'
        },
        {
          title: 'CONTACT US',
          content: 'If you have any questions or concerns, please don\'t hesitate to contact us via email support@craft-ify.com or through our social media channels.'
        }
      ],
      ipCopied: false
    }
  },
  mounted() {
  },
  methods: {
    copyIp() {
      let self = this;
      var textarea = document.createElement("textarea");
      textarea.value = self.ip;
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);
      self.ipCopied = true;
      
      setTimeout(()=> {
        self.ipCopied = false;
      }, 2000)
    },
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    }
  }
}
</script>