<template>
	<div class="menu">
		<ul class="nav">
			<li class="nav__item">
			<router-link to="/">
				<img src="./../assets/homeIcon.svg" class="img"/>
				<span class="desc">Home</span>
			</router-link>
			</li>
			<li class="nav__item">
			<router-link to="/pages/1">
				<img src="./../assets/rulesIcon.svg" class="img"/>
				<span class="desc">Rules</span>
			</router-link>
			</li>
		</ul>
		<router-link :to="'/store' + activeCategory.url" class="button red">
			Store
		</router-link>
	</div>
</template>

<script>

export default {
	name: 'Menu',
	props: ['activeCategory'],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
