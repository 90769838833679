<template>
  <main class="main page-inside products-page categories-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="title regular">
          <span>STORE</span>
          <router-link to="/cart" class="cart">
            <img v-if="$parent.cartContents.length" src="./../assets/cartActive.svg" class="img"/>
            <img v-else src="./../assets/cart.svg" class="img"/>
            <span class="desc">CART ({{ $parent.cartContents.length }})</span>
          </router-link>
        </div>
        <div class="wrapper">
          <transition name="fade">
            <div class="category-list" v-if="$parent.categories">
              <div class="category-list__item desc" 
                v-for="(item, i) in $parent.categories" 
                :key="i"
                @click="$emit('chooseCategoty', item)"
              >{{item.title}}</div>
            </div>
          </transition>
          <transition name="fade">
            <div class="product-list-container">
              <div class="active-category">
                <img :src="imgDomain  + activeCategory.image_url" class="img"/>
                <div class="desc">{{ activeCategory.title }}</div>
              </div>
              <div class="list products" v-if="products">
                <div class="item" v-for="(item, i) in products" :key="i">
                  <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
                </div>
              </div>
            </div>  
          </transition>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';

export default {
  name: 'Categories',
  props: ['addToCartChosenItem', 'currency', 'activeCategory', 'currencyCode'],
  components: {
    ProductCard
  },
  data: function() {
    return {
      imgDomain: '',
      products: [],
    }
  },
  watch: {
    activeCategory: function() {
      this.getProducts();
    },
    currencyCode: function() {
      this.getProducts();
    },
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.$http.get(process.env.VUE_APP_API + 'products/?category_id=' + this.activeCategory.id)
      .then((res) => {
        this.products = res.data.payload;
      })
      .catch(() => {
         
      })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
  }
}
</script>