<template>
  <div class="modal sign-modal page-inside">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="container">
            <div class="form">
              <div class="form-wrapper">
                <div class="form-fields login-content">
                  <div class="title">
                    SIGN IN
                  </div>
                  <div class="form-fields-wrapper">
                    <label>
                      <div class="desc">E-mail:</div>
                      <input type="email" placeholder="Email" v-model="email"/>
                    </label>
                    <label>
                      <div class="desc">Password:</div>
                      <input type="password" placeholder="Password" v-model="pass"/>
                    </label>
                    <div class="cta-container">
                      <div class="link desc" @click="switchToforgotPass">Forgot password?</div>
                      <button class="button" @click="submitLogin">
                        <span>Login</span>
                      </button>
                    </div>
                    <transition name="slide">
                      <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                    </transition>
                  </div>
                </div>
                <div class="modal-bottom">
                  <div class="title">Create Account</div>
                  <div class="desc">Enter your personal details and start game!</div>
                  <div class="button switch-register" @click="$parent.openSignUp">Sign Up</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      pass: ''
    }
  },
  mounted() {
    
  },
  methods: {
    switchToforgotPass() {
      this.$parent.clearError();
      this.$parent.openRecover();
    },
    switchToLogin() {
      this.$parent.clearError();
      this.$parent.openSignIn();
    },
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }
      this.$emit('login', loginData)
    }
  }
}
</script>