<template>
	<footer class="footer">
      <div class="footer-section">
        <div class="wrapper">
          <ul class="nav">
            <template  v-for="item in textPageList">
              <li class="nav__item" :key="item.id">
                <a class="desc" @click="goToPage(item)">
                  {{item.title}}
                </a>
              </li>
            </template>
            <li class="nav__item">
              <router-link to="/contacts" class="desc">
                CONTACT US
              </router-link>
            </li>
            <li class="nav__item">
              <router-link :to="'/store' + activeCategory.url" class="desc">
              STORE
            </router-link>
            </li>
            <li class="nav__item">
              <router-link to="/faq" class="desc">
                FAQ
              </router-link>
            </li>
          </ul>
          <div class="logo-container">
            <router-link to="/" class="logo">
              <img src="./../assets/logo.svg" class="img"/>
            </router-link>
          </div>
          <div class="text">
            <div class="desc">
              {{ $parent.settings.requisites }}<br/>
              {{ $parent.settings.support_email }}
            </div>
          </div>
          <div class="info-wrapper">
            <ul class="list list-pay">
              <li class="item">
                <img src="./../assets/mc_symbol.svg" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/visa.svg" class="img"/>
              </li>
            </ul>
            <div class="copyright" v-if="$parent.settings.copyright">{{ $parent.settings.copyright }}</div>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: ['settings', 'activeCategory'],
	data: function() {
		return {
      textPageList: []
		}
	},
	methods: {
		getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch(() => {
        
      })
    },
    goToPage(item) {
      this.$router.push({path: '/pages/' + item.id, params: {data: item.id}})
      this.$emit('getTextPageContent', item.id);
    }
	},
	mounted() {
    this.getTextPages()
    
  }
}
</script>
