<template>
  <main class="main page-inside text-page faq-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="title regular">FAQ</div>
        <transition name="fade">
          <div class="wrapper">
            <div class="accordion">
              <div class="item" v-for="(item, index) in faq" :key="index">
                <div class="panel" @click="togglePanel(index)">
                  <div v-if="isActivePanel(index)" class="minus"></div>
                  <div v-else class="plus"></div>
                  <div class="desc">{{ item.title }}</div>
                </div>
                <Transition name="slide">
                  <div class="panel-desc desc" v-if="isActivePanel(index)">
                    {{ item.content }}
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'TextPage',
  props: [],
  components: {
  },
  data: function() {
    return {
      faq: [],
      activePanels: [],
    }
  },
  mounted: async function() {
    this.$parent.getTextPageContent(this.$route.params.id);
    await Promise.all([this.getFaq()]);
  },
  methods: {
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
    getFaq() {
      return this.$http.get(process.env.VUE_APP_API + 'faq')
          .then((res) => {
            this.faq = res.data.payload.map(({question, answer}) => ({
              title: question,
              content: answer
            }));
          })
          .catch(() => {})
    },
  }
}
</script>